<template>
  <PageWithLayout>
    <Board
      :boardData="viewModel.boardData"
      :searchParams.sync="viewModel.searchParams"
      :dataList="viewModel.dataList"
      :paginationData="viewModel.paginationData"
      @onClickRow="(rowId,rowData) => viewModel.onClickRow(rowId,rowData)"
      @onSearch="viewModel.onSearch()">
      <template v-slot:headRight>
        <Button
          btnSize="small"
          btnStyle="secondary"
          text="추천 포스트 관리"
          :disabled="viewModel.hotDataList.length === 0"
          @onClickBtn="viewModel.onClickPostRecommedPopup()"/>
        <Button
          btnSize="small"
          btnStyle="primary"
          text="등록하기"
          routeName="LANDING_BLOG_POST_REGISTER"/>
      </template>
    </Board>
    <template v-slot:popup>
      <PostRecommedPopup
        v-if="viewModel.isPostRecommedPopup"
        :allPostDataList="viewModel.dataList"
        :hotDataList="viewModel.hotDataList"
        @onUpdatePostRecommedPopup="(value,index) => viewModel.onUpdatePostRecommedPopup(value,index)"
        @onClickClose="viewModel.onClickClosePostRecommedPopup()"
        @onClickComplete="result => viewModel.onClickCompletePostRecommedPopup(result)"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// popup
import PostRecommedPopup from '@/views/landing/blog/post/view/popup/PostRecommedPopup';
// viewModel
import PostListViewModel from '@/views/landing/blog/post/viewModel/PostListViewModel'

export default {
  name:'PostList',
  components:{
    PageWithLayout,
    Board,
    Button,

    PostRecommedPopup
  },
  beforeMount(){
    this.viewModel.init(this.$route.query);
  },
  data(){
    return{
      viewModel: new PostListViewModel(),
    }
  },
}
</script>